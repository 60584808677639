.myTimePickerContainer {
    position: relative;
    :global(.react-datepicker-wrapper) {
      display: block;
    }
    :global(.react-datepicker-popper) {
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
      z-index: 101;
    }
  }
  .headerTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    color: #5271ff;
    font-family: 'Poppins';
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
  }
  .headerSubtitle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    color: #979797;
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 24px;
  }
  .myTimePicker {
    display: block;
    width: 80%;
    margin-inline: auto;
    background-color: #f0f2ff;
    color: #5271ff;
    font-size: 15px;
    font-weight: 700;
    padding: 18px;
    border-radius: 14px;
    border: none;
  }
  
  .container {
    display: flex;
    gap: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // place-content: center;
    min-height: 40vh;
  }
  
  input[type='time' i]::-webkit-calendar-picker-indicator {
    background-image: url('../../../../../assests/images/clock.svg');
  }
  
  .customInputContent {
    display: flex;
    justify-content: space-between;
  }
  
  .clock {
    position: absolute;
    right: 42px;
    top: calc(50% - 14px);
    width: 20px;
    height: 20px;
    pointer-events: none;
  }
  
  .heading{
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    font-family: Poppins;
  }
  
  .line{
    height: 1px;
    width: 360px;
    background-color: black;
    opacity: 25%;
    margin-left: 20px;
  }
  
  
  .label{
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 2px;
    color: #5C5C5C;
  }
  
  .label2{
    font-size: 14px;
    font-family: 'Poppins';
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 88px;
  }
  .inputBox {
    border: 1px solid #dee2e6;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    border-radius: 6px;
    font-family: Poppins;
    width: 100%;
  }
  
  .inputBox:focus{
    outline: none;
    border: solid 2px #5271ff !important;
  }
  .icon {
    :hover{
      cursor: pointer;
    }
    position: absolute;
    top: 69%;
    left: 405px;
    transform: translateY(-50%);
    pointer-events: none; 
    opacity: 0.5;
    font-size: 12px;
    border: none;
    background: none;
    font-family: 'Poppins';
  }
  
  .inputContainer {
    position: relative;
    width: 100%;
  }
  
  .datePickerWrapper{
    position: absolute;
    z-index: 1000;
    background-color: #ffffff;
    margin-top: 5px;
    margin-left: 10px;
    top: 100%;
    left: 120px;
  }
  
  .timePickerWrapper{
    position: absolute;
    z-index: 1000;
    background-color: #ffffff;
    margin-top: 5px;
    margin-left: 10px;
    top: 100%;
    left: 310px;
  }
  
  @media (min-width: 360px) and (max-width: 767px){
    .myTimePicker {
      display: block;
      width: 80%;
      margin-inline: auto;
      background-color: #f0f2ff;
      color: #5271ff;
      font-size: 15px;
      font-weight: 700;
      padding: 18px;
      border-radius: 14px;
      border: none;
    }
    .noProperty{
      margin-top: 50px;
    }
    .container {
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // place-content: center;
        min-height: 40vh;
      }
    
    input[type='time' i]::-webkit-calendar-picker-indicator {
      background-image: url('../../../../../assests/images/clock.svg');
    }
    
    .customInputContent {
      display: flex;
      justify-content: space-between;
    }
    
    .clock {
      position: absolute;
      right: 42px;
      top: calc(50% - 14px);
      width: 20px;
      height: 20px;
      pointer-events: none;
    }
    
    .heading{
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 20px;
      font-family: Poppins;
      font-size: 12px;
    }
    
    .line{
      height: 1px;
      width: 300px;
      background-color: black;
      opacity: 25%;
      margin-left: 20px;
    }
    
    
    .label{
      font-size: 16px;
    }
    
    // .label2{
    //   font-size: 12px;
    //   font-family: 'Poppins';
    //   margin-top: 20px;
    //   margin-left: 20px;
    //   margin-right: 68px;
    // }
    .inputBox {
      border: 1px solid #dee2e6;
    //   margin-top: 15px;
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 12px;
      border-radius: 6px;
      width:100%;
      font-size: 16px;
      font-family: 'Poppins';
    }
    .inputBox:focus{
      outline: none;
      border: solid 2px #5271ff !important;
  }
    
    .icon {
      :hover{
        cursor: pointer;
      }
      position: absolute;
      top: 68%;
      left: 260px;
      transform: translateY(-50%);
      pointer-events: none; 
      opacity: 0.5;
      font-size: 16px;
      border: none;
      background: none;
      font-family: 'Poppins';
    }
    
    .inputContainer {
      position: relative;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: start;
        width: 100%;
    }
    
    .datePickerWrapper{
      position: absolute;
      z-index: 1000;
      background-color: #ffffff;
      margin-top: 5px;
      margin-left: 10px;
      top: 100%;
      left: 35px;
    }
    
    .timePickerWrapper{
      position: absolute;
      z-index: 1000;
      background-color: #ffffff;
      margin-top: 5px;
      margin-left: 10px;
      top: 100%;
      left: 85px;
    }
    .headerTitle{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        margin-top: 0;
        color: #5271ff;
        font-family: 'Poppins';
        font-weight: 900;
        font-size: 22px;
        line-height: 29.7px;
      }
      .headerSubtitle{
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-bottom: 30px;
        color: #979797;
        font-family: 'Poppins';
        font-size: 16px;
        line-height: 20px;
      }
  }
  
//   @media (min-width: 430px) and (max-width: 767px){
//     .myTimePicker {
//       display: block;
//       width: 80%;
//       margin-inline: auto;
//       background-color: #f0f2ff;
//       color: #5271ff;
//       font-size: 15px;
//       font-weight: 700;
//       padding: 18px;
//       border-radius: 14px;
//       border: none;
//     }
//     .noProperty{
//       margin-top: 50px;
//     }
    
//     .container {
//       padding: 0px;
//       margin-top: 80px;
//       margin-right: 80px;
//       margin-bottom: 50px;
//       width: 340px;
//       border-radius: 10px;
//       height: 180px;
//       aspect-ratio: 1;
//       box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15) !important;
//       font-family: 'Poppins';
//     }
    
//     input[type='time' i]::-webkit-calendar-picker-indicator {
//       background-image: url('../../../../../assests/images/clock.svg');
//     }
    
//     .customInputContent {
//       display: flex;
//       justify-content: space-between;
//     }
    
//     .clock {
//       position: absolute;
//       right: 42px;
//       top: calc(50% - 14px);
//       width: 20px;
//       height: 20px;
//       pointer-events: none;
//     }
    
//     .heading{
//       padding-top: 15px;
//       padding-bottom: 15px;
//       padding-left: 20px;
//       font-family: Poppins;
//       font-size: 12px;
//     }
    
//     .line{
//       height: 1px;
//       width: 300px;
//       background-color: black;
//       opacity: 25%;
//       margin-left: 20px;
//     }
    
    
//     .label{
//       font-size: 12px;
//       font-family: 'Poppins';
//       margin-top: 20px;
//       margin-left: 20px;
//       margin-right: 63px;
//     }
    
//     .label2{
//       font-size: 12px;
//       font-family: 'Poppins';
//       margin-top: 20px;
//       margin-left: 20px;
//       margin-right: 68px;
//     }
//     .inputBox {
//       border: 1px solid #dee2e6;
//       margin-top: 15px;
//       padding-left: 10px;
//       padding-top: 5px;
//       padding-bottom: 5px;
//       font-size: 12px;
//       border-radius: 6px;
//       width:65%;
//       font-size: 12px;
//       font-family: 'Poppins';
//     }
//     .inputBox:focus{
//       outline: none;
//       border: solid 2px #5271ff !important;
//   }
//     .icon {
//       :hover{
//         cursor: pointer;
//       }
//       position: absolute;
//       top: 65%;
//       left: 300px;
//       transform: translateY(-50%);
//       pointer-events: none; 
//       opacity: 0.5;
//       font-size: 12px;
//       border: none;
//       background: none;
//       font-family: 'Poppins';
//     }
    
//     .inputContainer {
//       position: relative;
//       width: 100%;
//     }
    
//     .datePickerWrapper{
//       position: absolute;
//       z-index: 1000;
//       background-color: #ffffff;
//       margin-top: 5px;
//       margin-left: 10px;
//       top: 100%;
//       left: 35px;
//     }
    
//     .timePickerWrapper{
//       position: absolute;
//       z-index: 1000;
//       background-color: #ffffff;
//       margin-top: 5px;
//       margin-left: 10px;
//       top: 100%;
//       left: 85px;
//     }
//     .headerTitle{
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         margin-bottom: 5px;
//         margin-top: 0;
//         color: #5271ff;
//         font-family: 'Poppins';
//         font-weight: 900;
//         font-size: 19.8px;
//         line-height: 29.7px;
//       }
//       .headerSubtitle{
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         // margin-bottom: 30px;
//         color: #979797;
//         font-family: 'Poppins';
//         font-size: 12.6px;
//         line-height: 18.9px;
//       }
//   }
  @media (min-width: 767px) and (max-width: 1024px){
    
    .datePickerWrapper{
      position: absolute;
      z-index: 1000;
      background-color: #ffffff;
      margin-top: 5px;
      margin-left: 10px;
      top: 100%;
      left: 65px;
    }
    
    .timePickerWrapper{
      position: absolute;
      z-index: 1000;
      background-color: #ffffff;
      margin-top: 5px;
      margin-left: 10px;
      top: 100%;
      left: 115px;
    }
  }