@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

/* variables */
$font-family-base: "Poppins", sans-serif;

// Font sizes
$font-size-base: 1rem;
$h1-size: 2.5rem;
$h2-size: 2rem;
$h3-size: 1.75rem;
$table-header: 1.125rem;
$sidebar-text: 1rem;
$body-text: 0.875rem;
$small-text: 0.688rem;

$xs-text: 0.675rem;
/* COLORS  */
$primary-color: #5271ff;
$secondary-color: #d8d8d8;
$color-black: #2c2d31;
$medium-black: #4344491a;
$color-black-light: rgba(76, 77, 82, 1);
$color-gray: #4c4d52;
$medium-gray: #999999;
$light-gray: #acacac;
$color-green: #0aba08;
$color-aqua: #4ee0a8;
$color-blue: #32ade6;
$color-red: #ff0004;
$color-orange: #ff9900;
$color-yellow: #fee400;
$color-white: #ffffff;
$color-purple: #9747ff;
$light-purple: #8280ff;
$dark-purple: #b93580;
$color-cyan: #ff3094;
$light-blue: #32ade6;
$color-darker-white: rgba(153, 153, 153, 0.1);

// Status Colors
$status-yellow: #ffe000;
$status-orange: #ff9d00;
$status-blue: #5765e7;
$status-cyan: #57e7e7;
$status-purple: #9747ff;

$role-badge-colors: (
  admin: $primary-color,
  agent: $color-gray,
);

$role-status-colors: (
  active: $color-green,
  pending: $color-red,
  revoked: $color-red,
);

$kha-actions: (
  assign-property: $color-green,
  unassign-property: $medium-gray,
  unassign-user: $medium-gray,
  assign-user: $primary-color,
  unknown: $color-red,
);

$status-colors: (
  "missed": $status-orange,
  "show": $color-green,
  "rescheduled": $status-purple,
  "cancelled": $color-red,
  "scheduled": $color-purple,
  "arrived": $status-blue,
  "assigned": $status-cyan,
  "pending": $secondary-color,
  "incomplete": $secondary-color,
  "archived": $light-gray,
);

$circle-colors: (
  completed: $color-green,
  returned: $status-purple,
  cancelled: $color-red,
  in-progress: $status-yellow,
  shipped: $status-orange,
  arrived: $status-blue,
  assigned: $status-cyan,
  pending: $secondary-color,
);

$action-colors: (
  "LOCK": $color-green,
  "UNLOCK": $color-red,
  "LOAD": $status-blue,
  "DELETE": $color-red,
  "UPDATE": $status-yellow,
);

$property-status: (
  active: $color-green,
  inactive: $color-red,
  lease-agreement-sent: $status-orange,
  application-received: $status-blue,
  sold: $color-black,
  rented: $status-purple,
  archived: $medium-black,
);

$code-status: (
  normal: $color-green,
  expired: $medium-gray,
);

$agent-color: (
  0: $primary-color,
  1: #7f84be,
  2: #528584,
  3: #96bfd0,
  4: #8f979b,
  5: #6c91a2,
);

$calendar-colors: (
  0: $primary-color,
  1: #7f84be,
  2: #528584,
  3: #96bfd0,
  4: #8f979b,
  5: #6c91a2,
);

$indexes: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9);
