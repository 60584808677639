.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    min-height: 60vh;
  }
  
  .companyName {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .tableContainer {
    width: 100%;
    max-height: 500px; 
    overflow-y: auto;
    margin-bottom: 50px;
  }
  .mainAddress{
    color: #4C4D52;
    font-family: "Poppins";
    font-size: 14px;
  }
  .cityAddress{
    color: #979797;
    font-family: "Poppins";
    font-size: 12px;
  }
  
  .propertyTable {
    width: 100%;
    border-collapse: collapse; /* Ensures borders collapse into a single line */
  }
  
  .propertyTable th,
  .propertyTable td {
    padding: 10px;
    text-align: left;
    border-left: none; /* Remove left border */
    border-right: none; /* Remove right border */
    border-top: none; /* Remove top border */
    border-bottom: 1px solid #ddd; /* Add only bottom border */
  }
  
  .propertyTable th {
    background-color: #f4f4f4;
    font-weight: bold;
    border-bottom: 2px solid #ddd; /* Slightly thicker bottom border for header */
  }
  
  .scrollableBody {
    max-height: 500px;
    overflow-y: auto;
  }
  
  .checkColumn {
    width: 10%;
    text-align: center;
  }
  
  .addressColumn {
    width: 50%;
  }
  
  .detailsColumn {
    width: 20%;
  }
  
  .imageColumn {
    width: 30%;
    text-align: center;
  }
  
  .checkInput {
    margin: 0;
  }
  
  .detailsButton {
    // padding: 5px 10px;
    font-size: 10px;
    font-family: 'Poppins';
    background-color: transparent;
    color: #5271ff;
    border: none;
    cursor: pointer;
  }
  
  .detailsButton:hover {
    background-color: #0056b3;
  }
  
  .propertyImage {
    width: 171px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  /* Adjustments for smaller screens */
  @media (max-width: 767px) {
    .container {
      width: 100%;
    }
  
    .propertyTable th,
    .propertyTable td {
      font-size: 14px;
    }
  }
  