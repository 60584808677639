.card {
  text-align: start;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 6px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.titleCard{
  font-size: 18px;
  color: #4C4D52;
  margin-top: 0;
}

.infoGroup {
  display: flex;
  // gap: 1rem;
}

.img {
  width: 30%;
  object-fit: cover;
  border-radius: 5px;
}

.link {
  margin-left: auto;
}

@media (max-width: 750px) {
  .card {
    padding: 20px !important;
  }
  .img {
    width: 40%;
    object-fit: cover;
    border-radius: 5px;
  }
  .titleCard{
    font-size: 14px;
    color: #4C4D52;
    margin-top: 0;
  }
  .subtitleCard{
    font-size: 12px;
  }
  .link {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 12px;
  }
}

@media (min-width: 751px) {
  .card {
    width: 550px;
    max-width: 90%;
    margin-inline: auto;
  }
  
  .link {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
