.react-datepicker {
    border: solid;
  }
  
  .react-datepicker__month-container {
    box-shadow: none;
  }
  
  .react-datepicker__navigation {
    top: 14px;
  }
  
  .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    width: 7px;
    height: 12px;
    border: none;
    // > span {
    //   display: none;
    // }
  }
  .react-datepicker__navigation--next {
    right: 25px !important;
    background: url('data:image/svg+xml;utf8,<svg width="7" height="12" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.40244 16.1633L9.17167 9.2883C9.37453 9.08227 9.5 8.83533 9.5 8.5C9.5 8.16467 9.37453 7.91773 9.17167 7.7117L2.40244 0.836698C1.96041 0.387768 1.27036 0.387768 0.828333 0.836698C0.390556 1.28131 0.390556 1.96869 0.828332 2.4133L6.82139 8.5L0.828332 14.5867C0.390555 15.0313 0.390554 15.7187 0.828331 16.1633C1.27036 16.6122 1.96041 16.6122 2.40244 16.1633Z" fill="%23000" stroke="%23000"/></svg>')
      no-repeat;
  }
  .react-datepicker__navigation--previous {
    left: 41px;
    background: url('data:image/svg+xml;utf8,<svg width="7" height="12" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.59756 0.836698L0.828332 7.7117C0.625471 7.91773 0.5 8.16467 0.5 8.5C0.5 8.83533 0.625471 9.08227 0.828332 9.2883L7.59756 16.1633C8.03959 16.6122 8.72964 16.6122 9.17167 16.1633C9.60944 15.7187 9.60944 15.0313 9.17167 14.5867L3.17861 8.5L9.17167 2.4133C9.60944 1.96869 9.60944 1.28131 9.17167 0.836698C8.72964 0.387767 8.03959 0.387767 7.59756 0.836698Z" fill="%23000" stroke="%23000"/></svg>')
      no-repeat;
  }
  
  .react-datepicker__day-names {
    margin-top: 0;
    border-top: 1px solid #dee2e6;
    max-width: 85%;
    margin-inline: auto;
  }
  
  .react-datepicker__day {
    width: 2rem;
    height: 2rem;
    font-weight: 500;
    font-size: 0.9rem;
  }
  
  .react-datepicker-time__caption {
    display: none !important;
  }
  .react-datepicker-time__input-container,
  .react-datepicker-time__input {
    width: 100%;
  }
  .react-datepicker__input-time-container {
    margin: 0;
  }
  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input {
    margin-left: 0px;
  }
  
  .react-datepicker__time-list-item--selected {
    background-color: #F5F5F5 !important;
    color: black !important;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px !important;
    border-radius: 8px;
  }
  @media (min-width: 767px) {
    .react-datepicker {
      border-radius: 10px;
      border-color: rgba(0,0,0,0.5);
      padding: 16px;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 5px 53px 0px rgba(0, 0, 0, 0.15);
    }
    .react-datepicker__navigation {
      top: 30px;
    }
    .react-datepicker__navigation--next {
      right: 50px !important;
    }
    .react-datepicker__time-container {
      // margin-left: 10px;
      border-left: none;
    }
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list {
      scrollbar-width: 4px;
      scrollbar-color: #979797;
    }
    .react-datepicker__time-container{
        width: 83px;
      }
  }
  @media (min-width: 360px) and (max-width: 429px){
    .react-datepicker__time-container{
      width: 225px;
    }
    .react-datepicker__time-box{
      width: 225px !important;
      text-align: left !important;
    }
    .react-datepicker__time-list-item--selected {
      background-color: #F5F5F5 !important;
      color: black !important;
      font-weight: bold;
      margin-left: 5px;
      margin-right: 5px;
      padding-left: 5px !important;
      border-radius: 8px;
  }
    
  }
  
  @media (min-width: 430px) and (max-width: 767px){
    .react-datepicker__time-container{
      width: 225px;
    }
    .react-datepicker__time-box{
      width: 225px !important;
      text-align: left !important;
    }
  }
  
  @media (min-width: 767px) and (max-width: 1024px){
    .react-datepicker__time-container{
      width: 225px;
    }
    .react-datepicker__time-box{
      width: 225px !important;
      text-align: left !important;
    }
  }
  
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    display: none !important;
  }
  
  