.container {
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // place-content: center;
  // min-height: 40vh;
}
.container > * {
  max-width: 600px;
  width: 100%;
}
.error {
  border: 1px solid red; 
}

.input {
  border: 1px solid #dee2e6;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  border-radius: 6px;
  font-family: Poppins;
  width: 100%;
}
.input:focus{
  outline: none;
  border: solid 2px #5271ff !important;
}
.input::placeholder{
  color: #A6A7A9;
}
.inputError {
  border: 1px solid #FF0000;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  border-radius: 6px;
  font-family: Poppins;
  width: 100%;
}
.inputError::placeholder{
  color: #A6A7A9;
}

.label{
  font-family: 'Poppins';
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 2px;
  color: #5C5C5C;
}
.headerTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  color: #5271ff;
  font-family: 'Poppins';
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
}
.headerSubtitle{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  color: #979797;
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 24px;
}

.icon {
  position: absolute;
    top: 48%;
    left: 10px; 
    transform: translateY(-50%);
    pointer-events: none; 
    opacity: 0.5;
    font-size: 12px;
    font-family: 'Poppins';
}
.iconError{
  position: absolute;
    top: 51%;
    left: 10px; 
    transform: translateY(-50%);
    pointer-events: none; 
    opacity: 0.5;
    font-size: 12px;
    font-family: 'Poppins';
    margin-top: -16.5px;
}

.error {
  background-color: rgba(220, 53, 69, 0.2);
  color: #4C4D52;
  border: none;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-family: 'Poppins';
  margin-top: 5px;
  padding-left: 12px;
  border-radius: 3px;
  height: 25px
}


.errorContainer{
  position: absolute;
  top: 94%;
  left: 43%;
  z-index:1000
}

.inputContainer {
  position: relative;
  width: 100%;
}

.agreement{
  font-family: Poppins;
  font-size: 10px;
}

@media (min-width: 360px) and (max-width: 767px){
  .container {
    display: grid;
    gap: 12px;
    place-content: center;
    min-height: 30vh;
    width: 90vw;
  }
  .container > * {
    max-width: 600px;
    width: 100%;
  }
  
  .input {
    border: 1px solid #dee2e6;
    font-size: 16px;
    border-radius: 6px;
    width: 100%;
  }

  .error {
    background-color: rgba(220, 53, 69, 0.2);
    color: #4C4D52;
    border: none;
    display: flex;
    align-items: center;
    font-size: 8px;
    font-family: 'Poppins';
    margin-top: 5px;
    padding-left: 12px;
    border-radius: 3px;
    height: 25px
  }
    
  .inputContainer {
    position: relative;
    width: 100%;
  }
  .headerTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 0;
    color: #5271ff;
    font-family: 'Poppins';
    font-weight: 900;
    font-size: 22px;
    line-height: 29.7px;
  }
  .headerSubtitle{
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 30px;
    color: #979797;
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 18.9px;
  }
  .label{
    font-size: 16px;
  }
}