.dropzone {
  img{
    max-height: 300px;
  }
  display: grid;
  gap: 8px;
  place-items: center;
  min-height: 100px;
  padding: 10px 14px;
  border-radius: 8px;
  background-color: white;
  border: 1px dashed #dee2e6;
  border-radius: 6px;
  font-size: 14px;
  // padding: 1rem;
  max-height: 350px;
  cursor: pointer;
  font-weight: 400;
}

.dropzone,
span {
  font-weight: 400;
  
  
}

