.layoutGrid {
  display: grid;
  grid-template-areas: 
    'booking images';  
  grid-template-columns: 1fr 1fr;  
  gap: 20px;  
  height: 100vh;
}

.layout {
  grid-area: booking; 
}
.layoutSteps{
  padding-block: 50px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  // min-height: 70vh;
  margin-bottom: 20px;
}
.layoutImages {
  grid-area: images;
  margin: 10px 0;
  height: 97%;
}
.boxBackground{
  background-color: #e9ecfa;
  width: 100%;
  height: 100%;
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(82, 113, 255, 0.2);
  border-radius: 3px;
}
.boxBackgroundZero{
  background-color: #e9ecfa;
  width: 100%;
  height: 100%;
  display: flex;
  // margin-bottom: 15px;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(82, 113, 255, 0.2);
  border-radius: 3px;
}
.onlyOneProp{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center; 
  margin-top: 50px; 
  gap: 50px;
}
.cardTitle {
  // font-weight: 600;
  opacity: 50%;
  font-size: 12px;
  text-align: start;
}
.heading {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 24px;
  font-weight: 700;
  color: #5271ff;
}
.header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container {
  max-width: 100%;
  margin-top: 50px;
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  // height: 50vh;
  margin-inline: auto;
}
.containerRes {
  max-width: 100%;
  // margin-left: 40px !important;
  margin: 10px 20px;
  display: flex;
  justify-content: center;
}

.stepLabels {
  margin-bottom: 12px;
}

.buttons {
  width: 435px;
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-direction: column-reverse;
  margin-top: 15px;
}

.button {
  display: flex;
  gap: 12px;
  justify-content: center;
  width: 100%;
}
.headerTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 0;
  color: #5271ff;
  font-family: 'Poppins';
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
}
.headerSubtitle{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  color: #4C4D52;
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
}

.headerTitleZero{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 100px;
  color: #5271ff;
  font-family: 'Poppins';
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
}
.headerSubtitleZero{
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-bottom: 30px;
  color: #979797;
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 24px;
}

.procModal{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  gap: 10px;
  text-align: center;
  
}

.modalTitle{
  color: #4C4D52;
  font-size: 20px;
  font-weight: 900 !important;
  
}

.modalDesc{
  color: #8C8C8C;
  font-size: 16px;
  margin: 10px 0;
}
.modalButton{
  width: 200px;
  height: 45px
}

.backButton{
  color:#4C4D52;
  border: none;
  font-size: 14px;
  padding: 0;
  margin-top: 10px;
  background-color: transparent;
}

// @media (max-width: 750px) {
//   .inactive {
//     display: none;
//   }
// }

@media (min-width: 751px) {
  .stepLabels {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    // gap: 5%;
  }
  .buttons {
    gap: 20px;
    flex-direction: row;
    margin-top: 50px;
    
  }
  .button{
    width: 50%;
  }
}

@media (min-width: 360px) and (max-width: 429px){
  .headerTitle{
    font-size:20px;
  }
  .headerSubtitle{
    font-size: 16px;
  }
  .stepLabels{
    display: flex;
    // margin-left: 25px;
  }

  .containerRes {
    max-width: 100%;
    // margin-left: 0px !important;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .modalButton{
    width: 170px;
    height: 45px;
    font-size: 14px !important;
  }
  .modalButton{
    width: 170px;
    height: 45px;
    font-size: 14px !important;
  }
}

@media (min-width: 430px) and (max-width: 767px){
  .headerTitle{
    font-size:24px;
  }
  .headerSubtitle{
    font-size: 14px;
  }
  .stepLabels{
    display: flex;
    // margin-left: 25px;
  }
  .modalButton{
    width: 180px;
    height: 45px
  }
  .containerRes {
    max-width: 100%;
    // margin-left: 0px !important;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}



@media (max-width: 1024px) {
  /* Hide the images section */
  .layoutImages {
    display: none;
  }

  /* Center the layout steps */
  .layoutSteps {
    width: 100%; /* Ensure it takes full width */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: 60px;
    // text-align: center;
  }

  /* Modify grid layout to stack items vertically */
  .layoutGrid {
    grid-template-areas:
      'booking' /* Only show the booking area */
      'images'; /* Images are removed in the next step, so we can ignore this */
    grid-template-columns: 1fr; /* Stack the elements vertically */
    gap: 20px;
  }

  .headerTitleZero{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 0;
    color: #5271ff;
    font-family: 'Poppins';
    font-weight: 900;
    font-size: 19.8px;
    line-height: 29.7px;
  }
  .headerSubtitleZero{
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 30px;
    color: #979797;
    font-family: 'Poppins';
    font-size: 12.6px;
    line-height: 18.9px;
  }

  .button{
    font-size: 24px !important;
  }

  .buttons{
    width: 201px;
    margin-top: 14px;
  }
}