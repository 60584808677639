.container {
    display: flex;
    gap: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // place-content: center;
    // min-height:50vh;
  }
  .container > * {
    max-width: 600px;
    width: 100%;
  }
  .card {
    display: grid;
    // gap: 8px;
    margin-top: 20px;
    margin-bottom: 0px;
    // height: 100%;
    // max-height: 300px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  }
  .error {
    border: 1px solid red; 
  }
  
  .label{
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    margin-left: 20px;
    color: #5C5C5C;
  }
  .input {
    border: 1px solid #dee2e6;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    border-radius: 6px;
    font-family: Poppins;
    width: 100%;
  }
  .input:focus{
    outline: none;
    border: solid 2px #5271ff !important;
  }
  .input::placeholder{
    color: #A6A7A9;
  }
  .inputError {
    border: 1px solid #FF0000;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    border-radius: 6px;
    font-family: Poppins;
    width: 100%;
  }
  .inputError::placeholder{
    color: #A6A7A9;
  }
  
  .headerTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    color: #5271ff;
    font-family: 'Poppins';
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
  }
  .headerSubtitle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    color: #979797;
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 24px;
  }
  
  .icon {
    position: absolute;
      top: 48%;
      left: 10px; 
      transform: translateY(-50%);
      pointer-events: none; 
      opacity: 0.5;
      font-size: 12px;
      font-family: 'Poppins';
  }
  .iconError{
    position: absolute;
      top: 51%;
      left: 10px; 
      transform: translateY(-50%);
      pointer-events: none; 
      opacity: 0.5;
      font-size: 12px;
      font-family: 'Poppins';
      margin-top: -16.5px;
  }
  
  .error {
    background-color: rgba(220, 53, 69, 0.2);
    color: #4C4D52;
    border: none;
    display: flex;
    align-items: center;
    font-size: 10px;
    font-family: 'Poppins';
    margin-top: 5px;
    padding-left: 12px;
    border-radius: 3px;
    height: 25px
  }
  .errorTerms{
    background-color: rgba(220, 53, 69, 0.2);
    color: #4C4D52;
    border: none;
    display: flex;
    align-items: center;
    font-size: 10px;
    font-family: 'Poppins';
    margin-top: 5px;
    margin-left: 20px;
    padding-left: 12px;
    border-radius: 3px;
    width: 95%;
    height: 25px
  }
  
  
  .errorContainer{
    position: absolute;
    top: 94%;
    left: 43%;
    z-index:1000
  }
  
  .inputContainer {
    position: relative;
    width: 100%;
  }
  
  .agreement{
    font-family: Poppins;
    font-size: 10px;
  }
  .checkboxGroup {
    cursor: pointer;
    align-items: start;
    display: flex;
    gap: 8px;
    padding-inline: 5%;
  }
  
  .checkboxGroup > input {
    margin-top: 6px;
  }
  
  .modal {
    font-family: 'Poppins';
    h1 {
      font-size: 20px;
      font-weight: 700;
    }
    h2,
    li::marker {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      font-weight: 500;
      font-size: 16px;
    }
    ol {
      display: grid;
      gap: 1rem;
    }
    .buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .closeButton{
    background: none;
    border: none;
    background-color: #ffffff;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
    margin: 10px
  }
  .closeContainer{
    display: flex;
    justify-content: end;
    align-items: end;
  }
  @media (min-width: 360px) and (max-width: 767px){
    .container {
        display: flex;
        gap: 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // place-content: center;
        min-height:30vh;
      }
    .container > * {
      max-width: 600px;
      width: 100%;
    }
    
    .card {
      display: grid;
      gap: 8px;
    //   margin-top: 80px;
    }
    
    .input {
      border: 1px solid #dee2e6;
      padding-left: 25px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 12px;
      border-radius: 6px;
      font-family: Poppins;
      width: 100%;
    }
  
    .error {
      background-color: rgba(220, 53, 69, 0.2);
      color: #4C4D52;
      border: none;
      display: flex;
      align-items: center;
      font-size: 8px;
      font-family: 'Poppins';
      margin-top: 5px;
      padding-left: 12px;
      border-radius: 3px;
      height: 25px
    }
    .errorTerms{
      background-color: rgba(220, 53, 69, 0.2);
      color: #4C4D52;
      border: none;
      display: flex;
      align-items: center;
      font-size: 8px;
      font-family: 'Poppins';
      margin-top: 5px;
      margin-left: 20px;
      padding-left: 12px;
      border-radius: 3px;
      width: 92%;
      height: 25px
    }
    
    .checkboxGroup {
      cursor: pointer;
      align-items: start;
      display: flex;
      gap: 8px;
      padding-inline: 5%;
    }
    
    .checkboxGroup > input {
      margin-top: 6px;
    }
    
    .modal {
      font-family: 'Poppins';
      h1 {
        font-size: 20px;
        font-weight: 700;
      }
      h2,
      li::marker {
        font-size: 16px;
        font-weight: 700;
      }
      p {
        font-weight: 500;
        font-size: 16px;
      }
      ol {
        display: grid;
        gap: 1rem;
      }
      .buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
    
    .icon {
      position: absolute;
        top: 48%;
        left: 10px; 
        transform: translateY(-50%);
        pointer-events: none; 
        opacity: 0.5;
        font-size: 12px;
        font-family: 'Poppins';
    }
    
    .inputContainer {
      position: relative;
      width: 100%;
    }
    
    .agreement{
      font-family: Poppins;
      font-size: 10px;
    }
    .headerTitle{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;
        margin-top: 15px;
        color: #5271ff;
        font-family: 'Poppins';
        font-weight: 900;
        font-size: 19.8px;
        line-height: 29.7px;
      }
      .headerSubtitle{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;
        color: #979797;
        font-family: 'Poppins';
        font-size: 12.6px;
        line-height: 18.9px;
      }
      .agreement{
        font-family: Poppins;
        margin-bottom: 15px;
        font-size: 10px;
      }
  }