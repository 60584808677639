$mobile-card-height: 120px;

@media (max-width: 756px) {
  .card {
    margin-top: 50px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
    width: 350px;
  }
}
@media (max-width: 574px) {
  .layout {
    padding-bottom: calc(10px + $mobile-card-height);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container{
  width: 75% ;
}

// .card{
//   width: 440px !important;
// }

// @media(max-width: 786px){
//   .card{
//     width: auto !important;
//   }
// }

.buttonContainer {
  display: flex;
  width: 100%;
  // position: fixed;
  // top: 100px;
  // left:10px;
}

.button{
  margin-top: 10px;
  width: 235px;
  height: 44px;
  font-size: 16px;
}
.layoutSteps{
  // padding-block: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
}

.layout {
  padding-inline: 1rem;
  display: flex;
  justify-content: start;
  flex-direction: column;
  // place-content: center;
  gap: 50px !important;
}

// .layout{
//   display: grid;
//   gap: 1rem;
// }
.header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card {
  outline: none;
  display: grid;
  margin-left: 5%;
  width: 90%;
  gap: 1rem;
}

.cardTitle {
  // font-weight: 600;
  opacity: 50%;
  font-size: 12px;
  text-align: center;
}

.cardTitle,
.heading {
  line-height: 1.5rem;
}

.information {
  font-size: 14px;
}

.information > hr {
  margin: 10px 0;
  border: 1px solid #dee2e6;
}
.information > hr:first-of-type {
  margin: 0px 0px 5px 0px;
}

.heading {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 24px;
  font-weight: 700;
  color: #5271ff;
}
.heading > img {
  width: 24px;
}

.notificationStatusGroup {
  display: flex;
  justify-content: center;
  gap: 8px;
}

// @media (max-width: 600px) {
//   .notificationStatusGroup {
//     flex-direction: column;
//   }
// }

.notificationStatus {
  margin: 0;
  margin-bottom: 20px;
  display: flex;
  gap: 0.25rem;
  font-size: 10px;
  color: #979797;
}

.bottomText {
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}

.link {
  text-decoration: none;
  font-weight: 500;
  color: #5271ff;
  text-decoration: underline;
}
.link:hover {
  text-decoration: underline;
}
.leftTd{
  opacity: 0.5;
  text-align: left;
  
  font-size: 12px;
  padding: 15px;
  padding-right: 50px;
}

.rightTd{
  opacity: 0.8;
  text-align: right;
  
  font-size: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 100px;
  padding-right: 15px;
}

.rows{
  border-bottom: solid;
  border-top: none;
  border-width: thin;
  border-right: none;
  border-left: none;
  border-color: #e0e0e0;
}

.bottomRow{
  border-style: none;
}

.table{
  width: 100%
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #5271ff;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.layoutImages{
  grid-area: images;
  margin: 10px 0;
  height: 97%;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@media (min-width: 360px) and (max-width: 429px){
  .leftTd{
    opacity: 0.5;
    text-align: left;
    
    font-size: 10px;
    padding: 0px;
    padding-right: 10px;
  }
  
  .rightTd{
    opacity: 0.8;
    text-align: right;
    
    font-size: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .rows{
    border-bottom: solid;
    border-top: none;
    border-width: thin;
    border-right: none;
    border-left: none;
    border-color: #e0e0e0;
  }
 
}

@media (min-width: 430px) and (max-width: 767px){
  .leftTd{
    opacity: 0.5;
    text-align: left;
    
    font-size: 10px;
    padding: 15px;
    padding-right: 10px;
  }
  
  .rightTd{
    opacity: 0.8;
    text-align: right;
    
    font-size: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 15px;
  }
  
  .rows{
    border-bottom: solid;
    border-top: none;
    border-width: thin;
    border-right: none;
    border-left: none;
    border-color: #e0e0e0;
  }
}